.testimonials-section {
  background-color: var(--background-color);
  position: relative;
  padding: 80px 0;
}

.testimonials-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(13, 13, 13, 0.4);
  border-radius: 50px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 16px;
  margin: 0 auto 24px;
  text-align: center;
  width: fit-content;
}

.testimonials-badge span {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color);
}

.section-header {
  text-align: center;
  margin-bottom: 60px;
}

.section-title {
  font-family: var(--font-sans);
  font-weight: 400;
  font-size: 48px;
  line-height: 1.2;
  letter-spacing: -0.03em;
  margin-bottom: 16px;
  color: var(--text-color);
}

/* Removed as it's now handled by the global .italic class */

.section-subtitle {
  font-family: var(--font-sans);
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--text-color-secondary);
  max-width: 700px;
  margin: 0 auto;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.testimonial-card {
  background-color: rgb(13, 13, 13);
  border-radius: 20px;
  padding: 30px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
}

/* Removed hover animation */

.rating {
  display: flex;
  gap: 2px;
  margin-bottom: 16px;
}

.star {
  color: #FFD700;
  font-size: 18px;
}

.testimonial-quote {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
  opacity: 0.6;
  margin-bottom: 20px;
  flex-grow: 1;
  min-height: 48px;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: auto;
}

.author-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.author-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.author-info {
  display: flex;
  flex-direction: column;
}

.author-name {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: var(--text-color);
  margin-bottom: 2px;
}

.author-title {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: var(--text-color);
  opacity: 0.6;
}

@media (max-width: 1199px) {
  .testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .section-title {
    font-size: 40px;
  }
}

@media (max-width: 809px) {
  .testimonials-grid {
    grid-template-columns: 1fr;
  }
  
  .section-title {
    font-size: 32px;
  }
  
  .section-subtitle {
    font-size: 16px;
  }
  
  .testimonial-card {
    padding: 24px;
  }
}