/* Animated Hero Text Styles */

.animated-hero-title {
  font-family: var(--font-sans);
  font-weight: 400;
  font-size: 72px;
  line-height: 1.1;
  letter-spacing: -0.03em;
  margin-bottom: 36px; /* Increased bottom margin for more space */
  color: var(--text-color);
  text-align: center;
  perspective: 4000px; /* Even more dramatic perspective for the 3D effect */
  overflow: visible; /* Allow words to overflow during animation */
  padding: 30px 0; /* More padding to accommodate the larger animation */
  min-height: 200px; /* Ensure consistent height during animation */
  position: relative; /* For proper z-index handling */
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.animated-word {
  display: inline-block;
  margin-right: 0.3em;
  opacity: 0;
  filter: blur(50px);
  transform: translateZ(-800px) translateY(100px) scale(8);
  transition: 
    opacity 0.9s cubic-bezier(0.16, 1, 0.3, 1),
    filter 0.9s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.9s cubic-bezier(0.16, 1, 0.3, 1);
  will-change: opacity, filter, transform;
  transform-origin: center center;
  position: relative;
  z-index: 10; /* Keep words above other content during animation */
}

.animated-word:last-child {
  margin-right: 0;
}

.animated-word-visible {
  opacity: 1;
  filter: blur(0);
  transform: translateZ(0) translateY(0) scale(1);
}

/* Responsive adjustments */
@media (max-width: 1199px) {
  .animated-hero-title {
    font-size: 56px;
  }
}

@media (max-width: 809px) {
  .animated-hero-title {
    font-size: 40px;
  }
}