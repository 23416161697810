.faq-section {
  background-color: var(--background-color);
  position: relative;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto 60px;
}

.faq-item {
  background-color: var(--card-background);
  border-radius: 12px;
  margin-bottom: 16px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-question-container {
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: 
    background-color 0.3s ease,
    border-color 0.3s ease;
}

.faq-question-container:hover {
  background-color: rgba(20, 20, 20, 0.8);
}

.faq-question-container.active {
  background-color: rgba(73, 48, 235, 0.1);
  border-color: rgba(73, 48, 235, 0.3);
}

.faq-question {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question h3 {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: var(--text-color);
  margin: 0;
}

.faq-icon {
  font-size: 24px;
  color: var(--primary-color);
  transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
}

.faq-question-container.active .faq-icon {
  transform: rotate(180deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-10px);
  transition: 
    max-height 0.4s cubic-bezier(0.33, 1, 0.68, 1),
    opacity 0.3s ease,
    transform 0.3s ease,
    padding 0.3s ease;
  padding: 0 20px 0;
}

.faq-answer.open {
  max-height: 600px; /* Large enough to contain all possible answers */
  opacity: 1;
  transform: translateY(0);
  padding: 0 20px 20px;
}

.faq-answer p {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  color: var(--text-color-secondary);
  line-height: 1.5;
}

.faq-cta {
  text-align: center;
}

.faq-cta p {
  color: var(--text-color);
  margin-bottom: 16px;
}

@media (max-width: 809px) {
  .faq-question h3 {
    font-size: 16px;
  }
  
  .faq-question {
    padding: 16px;
  }
}