.how-it-works-section {
  background-color: #000;
  position: relative;
  padding: 80px 0;
}

.how-it-works-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(13, 13, 13, 0.4);
  border-radius: 50px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 16px;
  margin: 0 auto 24px;
  text-align: center;
  width: fit-content;
}

.how-it-works-badge span {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color);
}

.how-it-works-header {
  text-align: center;
  margin-bottom: 60px;
}

.how-it-works-title {
  font-family: var(--font-sans);
  font-weight: 400;
  font-size: 48px;
  line-height: 1.2;
  letter-spacing: -0.03em;
  margin-bottom: 16px;
  color: var(--text-color);
}

/* Removed as it's now handled by the global .italic class */

.how-it-works-subtitle {
  font-family: var(--font-sans);
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--text-color-secondary);
  max-width: 700px;
  margin: 0 auto;
}

.steps-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin-top: 20px;
}

.step-card {
  background-color: rgb(13, 13, 13);
  border-radius: 24px;
  padding: 30px;
  padding-top: 40px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
}

/* Removed hover animation */

.step-number {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(20, 20, 20, 0.95);
  border-radius: 50%;
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.step-image-container {
  height: 260px;
  margin-bottom: 24px;
  position: relative;
  border-radius: 0;
  overflow: hidden;
  background-color: rgb(13, 13, 13);
}

.step-image {
  display: block;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
  position: relative;
  z-index: 1;
}

.step-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: radial-gradient(circle at center, rgba(13, 13, 13, 0) 30%, rgba(13, 13, 13, 0.9) 75%, rgb(13, 13, 13) 100%);
  pointer-events: none;
}

.step-title {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 12px;
  color: var(--text-color);
}

.step-description {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color-secondary);
}

@media (max-width: 1199px) {
  .how-it-works-title {
    font-size: 40px;
  }
  
  .how-it-works-subtitle {
    font-size: 16px;
  }
}

@media (max-width: 809px) {
  .steps-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .how-it-works-title {
    font-size: 32px;
  }
  
  .step-title {
    font-size: 20px;
  }
}