.footer {
  position: relative;
  padding: 80px 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(20px);
  border-top: 1px solid var(--border-color);
}

.footer-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Background image will be set directly in the component */
  background-size: cover;
  background-position: center;
  opacity: 0.1;
  mask: radial-gradient(50% 50% at 50% 50%, #000 0%, rgba(0, 0, 0, 0) 100%);
  filter: hue-rotate(33deg);
  z-index: 0;
}

.footer-container {
  position: relative;
  z-index: 1;
}

.footer-top {
  display: flex;
  margin-bottom: 60px;
}

.footer-logo {
  flex: 1;
  max-width: 150px;
}

.footer-newsletter {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.newsletter-content h3 {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
  color: var(--text-color);
}

.newsletter-content p {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color-secondary);
  margin-bottom: 20px;
  max-width: 400px;
}

.newsletter-form {
  width: 100%;
}

.form-group {
  display: flex;
  gap: 10px;
}

.footer-middle {
  display: flex;
  justify-content: space-between;
}

.footer-links {
  display: flex;
  gap: 80px;
}

.footer-links-group h4 {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 20px;
}

.footer-links-group ul {
  list-style: none;
  padding: 0;
}

.footer-links-group ul li {
  margin-bottom: 10px;
}

.footer-links-group ul li a {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color-secondary);
  transition: color 0.2s ease;
}

.footer-links-group ul li a:hover {
  color: var(--primary-color);
}

.footer-company p {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color-secondary);
  line-height: 1.5;
}

@media (max-width: 1199px) {
  .footer-top {
    flex-direction: column;
    gap: 40px;
  }
  
  .footer-logo {
    margin-bottom: 20px;
  }
}

@media (max-width: 809px) {
  .footer {
    padding: 60px 0;
  }
  
  .form-group {
    flex-direction: column;
  }
  
  .footer-middle {
    flex-direction: column;
    gap: 40px;
  }
  
  .footer-links {
    flex-direction: column;
    gap: 40px;
  }
}