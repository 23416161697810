.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid var(--border-color);
  transition: all 0.3s ease;
}

.navigation.scrolled {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 80px;
}

.nav-logo {
  height: 40px;
  width: auto;
}

.nav-links {
  display: flex;
  gap: 32px;
}

.nav-link {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: var(--text-color);
  position: relative;
  transition: opacity 0.2s ease;
}

.nav-link:hover {
  opacity: 0.8;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: width 0.2s ease;
}

.nav-link:hover::after {
  width: 100%;
}

.mobile-menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1001;
}

.mobile-menu-toggle span {
  width: 100%;
  height: 2px;
  background-color: var(--text-color);
  transition: all 0.3s ease;
}

.mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.mobile-nav.open {
  transform: translateX(0);
}

.mobile-nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 24px;
}

.mobile-download {
  margin-top: 20px;
}

@media (max-width: 809px) {
  .desktop-nav {
    display: none;
  }
  
  .mobile-menu-toggle {
    display: flex;
  }
  
  .mobile-nav {
    display: block;
  }
  
  .nav-container {
    padding: 16px;
    height: 70px;
  }
}

.admin-link {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 15px;
  transition: color 0.2s ease;
  text-decoration: none;
}

.admin-link:hover {
  color: rgba(255, 255, 255, 0.8);
}