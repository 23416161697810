.legal-page {
  padding: 140px 0 80px;
}

.legal-content {
  max-width: 800px;
  margin: 0 auto;
  background-color: var(--card-background);
  border-radius: 20px;
  padding: 40px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.legal-content h1 {
  font-size: 36px;
  color: var(--text-color);
  margin-bottom: 10px;
}

.last-updated {
  color: var(--text-color-secondary);
  font-size: 14px;
  margin-bottom: 40px;
}

.legal-section {
  margin-bottom: 40px;
}

.legal-section h2 {
  font-size: 24px;
  color: var(--text-color);
  margin-bottom: 20px;
}

.legal-section h3 {
  font-size: 18px;
  color: var(--text-color);
  margin-bottom: 15px;
  margin-top: 30px;
}

.legal-section p {
  font-size: 16px;
  color: var(--text-color-secondary);
  line-height: 1.6;
  margin-bottom: 15px;
}

.legal-section ul, .legal-section ol {
  margin-bottom: 20px;
  padding-left: 20px;
  color: var(--text-color-secondary);
}

.legal-section li {
  margin-bottom: 10px;
  line-height: 1.6;
}

@media (max-width: 809px) {
  .legal-page {
    padding: 120px 0 60px;
  }
  
  .legal-content {
    padding: 30px 20px;
  }
  
  .legal-content h1 {
    font-size: 28px;
  }
  
  .legal-section h2 {
    font-size: 20px;
  }
  
  .legal-section h3 {
    font-size: 16px;
  }
  
  .legal-section p, .legal-section li {
    font-size: 14px;
  }
}