.unsubscribe-page {
  padding: 140px 0 80px;
}

.unsubscribe-content {
  max-width: 600px;
  margin: 0 auto;
  background-color: var(--card-background);
  border-radius: 20px;
  padding: 40px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.unsubscribe-content h1 {
  font-size: 32px;
  color: var(--text-color);
  margin-bottom: 20px;
}

.unsubscribe-intro {
  font-size: 16px;
  color: var(--text-color-secondary);
  line-height: 1.6;
  margin-bottom: 30px;
}

.unsubscribe-form {
  margin-top: 30px;
}

.form-field {
  margin-bottom: 24px;
}

.form-field label {
  display: block;
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 8px;
}

.form-actions {
  margin-top: 40px;
}

.submission-success {
  margin-top: 30px;
  padding: 30px;
  background-color: rgba(73, 48, 235, 0.1);
  border-radius: 12px;
  border: 1px solid rgba(73, 48, 235, 0.3);
}

.submission-success h2 {
  font-size: 24px;
  color: var(--text-color);
  margin-bottom: 16px;
}

.submission-success p {
  font-size: 16px;
  color: var(--text-color-secondary);
  line-height: 1.6;
  margin-bottom: 15px;
}

@media (max-width: 809px) {
  .unsubscribe-page {
    padding: 120px 0 60px;
  }
  
  .unsubscribe-content {
    padding: 30px 20px;
  }
  
  .unsubscribe-content h1 {
    font-size: 28px;
  }
  
  .submission-success {
    padding: 20px;
  }
  
  .submission-success h2 {
    font-size: 20px;
  }
}