.setup-2fa-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  padding: 20px;
}

.setup-2fa-box {
  width: 100%;
  max-width: 650px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.setup-2fa-box h1 {
  margin-bottom: 20px;
  color: #333;
  font-size: 28px;
  text-align: center;
}

.setup-2fa-box > p {
  margin-bottom: 30px;
  color: #666;
  text-align: center;
}

.steps-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.step {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border-left: 4px solid #a777e3;
}

.step h3 {
  margin-bottom: 10px;
  color: #333;
}

.step p {
  color: #666;
  margin-bottom: 15px;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.secret-key {
  font-family: monospace;
  font-size: 18px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  text-align: center;
  margin: 15px 0;
  letter-spacing: 1px;
}

.code-input-container {
  margin: 15px 0;
}

.code-input-container input {
  width: 100%;
  padding: 12px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 2px;
}

.verify-button {
  width: 100%;
  padding: 12px;
  background-color: #a777e3;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.verify-button:hover {
  background-color: #8a63c8;
}

.verify-button:disabled {
  background-color: #ccb6e6;
  cursor: not-allowed;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 18px;
  color: #666;
}

.error-message {
  margin-top: 20px;
  color: #e74c3c;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 768px) {
  .setup-2fa-box {
    padding: 25px;
  }
}