.cta-section {
  background-color: var(--background-color);
  position: relative;
  padding: 100px 0;
}

.cta-card {
  background: linear-gradient(135deg, rgba(73, 48, 235, 0.2) 0%, rgba(13, 13, 13, 0.6) 100%);
  border-radius: 24px;
  padding: 60px;
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
}

.cta-content {
  position: relative;
  z-index: 2;
  max-width: 600px;
  margin: 0 auto;
}

.cta-content h2 {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 42px;
  color: var(--text-color);
  margin-bottom: 16px;
  letter-spacing: -0.03em;
}

.cta-content p {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: var(--text-color-secondary);
  margin-bottom: 40px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.cta-decoration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.5;
}

.cta-decoration-circle {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(circle, var(--primary-color) 0%, rgba(73, 48, 235, 0) 70%);
}

.cta-decoration-circle:nth-child(1) {
  width: 400px;
  height: 400px;
  top: -200px;
  right: -100px;
}

.cta-decoration-circle:nth-child(2) {
  width: 300px;
  height: 300px;
  bottom: -150px;
  left: -50px;
}

.cta-decoration-circle:nth-child(3) {
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 809px) {
  .cta-card {
    padding: 40px 20px;
  }
  
  .cta-content h2 {
    font-size: 32px;
  }
  
  .cta-content p {
    font-size: 16px;
  }
  
  .cta-buttons {
    flex-direction: column;
  }
}