/* Import Google Fonts for Instrument Serif */
@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&display=swap');

/* Base styles */
:root {
  --primary-color: rgb(73, 48, 235);
  --background-color: rgb(0, 0, 0);
  --text-color: rgb(255, 255, 255);
  --text-color-secondary: rgba(255, 255, 255, 0.7);
  --border-color: rgba(255, 255, 255, 0.08);
  --card-background: rgba(13, 13, 13, 0.4);
  --button-primary: rgb(73, 48, 235);
  --button-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px -2px, rgba(0, 0, 0, 0.1) 0px 2px 2px -1px, rgba(73, 48, 235, 0.12) 0px 0px 0px 1px;
  --button-border-radius: 10px;
  --border-radius-standard: 8px;
  --input-border-color: rgba(255, 255, 255, 0.23);
  --font-serif: 'Instrument Serif', Georgia, serif;
  --font-sans: "Helvetica Neue", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Using system fonts that closely match Helvetica Neue */

html, body {
  font-family: var(--font-sans);
  scroll-behavior: smooth;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.5;
  letter-spacing: -0.02em;
}

.lenis {
  height: 100vh;
  overflow: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

button, input[type="submit"] {
  cursor: pointer;
  border: none;
  font-family: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

/* Utility classes */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.italic {
  font-family: var(--font-serif);
  font-style: italic;
  letter-spacing: 0;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

/* Scroll Animation Classes */
.animate-on-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.animate-visible {
  opacity: 1;
  transform: translateY(0);
}

/* Animation delay classes for staggered animations */
.delay-100 { transition-delay: 0.1s; }
.delay-200 { transition-delay: 0.2s; }
.delay-300 { transition-delay: 0.3s; }
.delay-400 { transition-delay: 0.4s; }
.delay-500 { transition-delay: 0.5s; }

/* Responsive breakpoints */
@media (min-width: 1200px) {
  /* Desktop styles */
}

@media (min-width: 810px) and (max-width: 1199px) {
  /* Tablet styles */
}

@media (max-width: 809px) {
  /* Mobile styles */
  
  /* Reduce animation transition time on mobile for better performance */
  .animate-on-scroll {
    transition: opacity 0.6s ease, transform 0.6s ease;
  }
}