/* App-specific styles */
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Button styles */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: var(--button-border-radius);
  font-family: "Helvetica Neue", "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.02em;
  transition: all 0.2s ease;
}

.btn-primary {
  background-color: var(--button-primary);
  color: var(--text-color);
  box-shadow: var(--button-shadow);
}

.btn-primary:hover {
  background-color: rgba(73, 48, 235, 0.9);
}

.btn-outline {
  background-color: transparent;
  color: var(--text-color);
  box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px;
}

.btn-outline:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Form styles */
.form-input {
  background-color: rgba(186, 186, 186, 0);
  border: 1px solid var(--input-border-color);
  border-radius: 10px;
  padding: 12px 16px;
  color: var(--text-color);
  width: 100%;
}

.form-input::placeholder {
  color: rgb(153, 153, 153);
}

/* Backdrop blur */
.backdrop-blur {
  backdrop-filter: blur(10px);
}

/* Section styles */
.section {
  padding: 80px 0;
}

@media (max-width: 809px) {
  .section {
    padding: 60px 0;
  }
  
  .btn {
    padding: 8px 16px;
  }
}