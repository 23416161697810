.verify-2fa-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  padding: 20px;
}

.verify-2fa-box {
  width: 100%;
  max-width: 400px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.verify-2fa-box h1 {
  margin-bottom: 20px;
  color: #333;
  font-size: 28px;
}

.verify-2fa-box p {
  margin-bottom: 30px;
  color: #666;
}

.code-input-container {
  margin-bottom: 20px;
}

.code-input-container input {
  width: 100%;
  padding: 12px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 2px;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #a777e3;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #8a63c8;
}

.submit-button:disabled {
  background-color: #ccb6e6;
  cursor: not-allowed;
}

.error-message {
  margin-top: 20px;
  color: #e74c3c;
  font-size: 14px;
}