.hero-section {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 120px 0 120px; /* Increased top padding to create more space */
  overflow: hidden;
}

.hero-container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.hero-content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
  overflow: visible;
}

.hero-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(13, 13, 13, 0.4);
  border-radius: 50px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 16px;
  margin-bottom: 36px; /* Increased margin for more space before the hero title */
  margin-top: 20px; /* Added top margin as well */
}

.hero-badge span {
  font-family: "Helvetica Neue", "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color);
}

.hero-title {
  font-family: "Helvetica Neue", "Inter", sans-serif;
  font-weight: 400;
  font-size: 72px;
  line-height: 1.1;
  letter-spacing: -0.03em;
  margin-bottom: 24px;
  color: var(--text-color);
}

.hero-description {
  font-family: "Helvetica Neue", "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--text-color-secondary);
  margin-bottom: 50px; /* Increased bottom margin for more space before buttons */
  max-width: 600px;
}

.hero-cta {
  display: flex;
  gap: 16px;
  margin-bottom: 60px;
}

.hero-btn {
  min-width: 150px;
}

.hero-video-container {
  width: 100%;
  max-width: 900px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  transition: transform 1s cubic-bezier(0.33, 1, 0.68, 1), translate 1s cubic-bezier(0.33, 1, 0.68, 1);
  transform-origin: center center;
  will-change: transform, translate;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

/* Wrapper to handle the overflow properly */
.video-wrapper {
  overflow: visible;
  padding: 40px;
  margin: 0 auto;
  width: 100%;
  max-width: 980px; /* Leave room for scaling */
}

.hero-video {
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
  display: block;
  transition: transform 0.8s cubic-bezier(0.33, 1, 0.68, 1); /* Slower, more pronounced transition */
  transform-origin: center center;
  will-change: transform; /* Optimize for animation performance */
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,1) 100%);
  z-index: 1;
}

@media (max-width: 1199px) {
  .hero-title {
    font-size: 56px;
  }
  
  .hero-video-container {
    max-width: 700px;
  }
}

@media (max-width: 809px) {
  .hero-title {
    font-size: 40px;
  }
  
  .hero-description {
    font-size: 16px;
  }
  
  .hero-cta {
    flex-direction: column;
    width: 100%;
  }
  
  .hero-btn {
    width: 100%;
  }
  
  .hero-video-container {
    max-width: 100%;
  }
}