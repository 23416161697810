.brand-logos-section {
  background-color: var(--background-color);
  padding: 60px 0;
  overflow: hidden;
  text-align: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.logos-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding: 40px 0;
}

.logos-container::before,
.logos-container::after {
  content: "";
  position: absolute;
  top: 0;
  width: 200px;
  height: 100%;
  z-index: 2;
  pointer-events: none; /* Ensure the hover still works underneath */
}

.logos-container::before {
  left: 0;
  background: linear-gradient(to right, var(--background-color) 20%, rgba(0, 0, 0, 0));
}

.logos-container::after {
  right: 0;
  background: linear-gradient(to left, var(--background-color) 20%, rgba(0, 0, 0, 0));
}

.logos-scroll {
  display: flex;
  animation: scroll 30s linear infinite;
  width: max-content;
}

.logos-container:hover .logos-scroll {
  animation-play-state: paused;
}

.logo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 40px;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.logo-item:hover {
  opacity: 1;
}

.logo-item img {
  height: 50px;
  width: auto;
  filter: grayscale(100%) brightness(200%);
  transition: filter 0.3s ease;
}

.logo-item:hover img {
  filter: grayscale(0%) brightness(100%);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50% - 40px)); /* Adjust based on logo margin */
  }
}

@media (max-width: 809px) {
  .brand-logos-section {
    padding: 40px 0;
  }
  
  .logos-scroll {
    animation: scroll 20s linear infinite;
  }
  
  .logo-item {
    margin: 0 20px;
  }
  
  .logo-item img {
    height: 40px;
  }
}