.not-found-page {
  min-height: calc(100vh - 80px - 400px); /* Subtract header and footer heights */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  text-align: center;
}

.not-found-content {
  max-width: 600px;
  margin: 0 auto;
}

.not-found-content h1 {
  font-size: 120px;
  color: var(--primary-color);
  line-height: 1;
  margin-bottom: 20px;
}

.not-found-content h2 {
  font-size: 32px;
  color: var(--text-color);
  margin-bottom: 16px;
}

.not-found-content p {
  font-size: 18px;
  color: var(--text-color-secondary);
  margin-bottom: 40px;
}